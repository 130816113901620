<template>
  <v-card flat class="pb-8">
    <v-card-title style="z-index: 2" class="px-0">
      <v-btn icon :ripple="false" @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">Preview page</span>
      <v-spacer/>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <template v-if="form.main_part || form.aside_part">
          <v-col cols="6" class="py-0">
            <v-row class="ma-0 px-0">
              <v-col cols="12" class="ma-0 px-0">
                <template v-if="form.main_part">
                  <v-card min-height="100">
                    <v-card-title>Content part</v-card-title>
                    <v-card-text>
                      <froala-view v-model="form.main_part"></froala-view>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" class="ma-0 px-0">
                <template v-if="form.aside_part">
                  <v-card min-height="100">
                    <v-card-title>Aside part</v-card-title>
                    <v-card-text>
                      <froala-view v-model="form.aside_part"></froala-view>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="12" class="ma-0 px-0">
                <template v-if="form.settings['is_slider_on']">
                  <template v-if="form.settings.images.length > 0">
                    <v-card min-height="100">
                      <v-card-title>Slider images</v-card-title>
                      <v-card-text>
                        <div v-for="(item) in form.settings.images"
                             style="position: relative"
                             class="pa-4"
                             :key="item.id">
                          <v-hover v-slot="{ hover }">
                            <v-img style="transition: .3s ease-in-out" :class="{ 'elevation-2': hover, 'mx-2': true }" contain max-width="70" :src="item['image_data_url']">
                            </v-img>
                          </v-hover>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col :cols="form.main_part || form.aside_part ? 6 : 8">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-card>
                <v-card-title>
                  Page metadata
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <template v-if="form.meta">
                      <v-col cols="6">
                        <v-text-field v-model="form.meta.meta_title" type="text" disabled
                                      label="Title meta-tag"></v-text-field>
                        <div>
                          <label disabled="disabled">Meta description</label>
                          <froala-view v-model="form.meta.meta_description"></froala-view>
                        </div>
                      </v-col>
                    </template>
                    <template v-if="form.og">
                    <v-col cols="6">
                      <v-text-field v-model="form.og.og_title" type="text" disabled
                                    label="Title OG meta-tag"></v-text-field>
                      <v-text-field v-model="form.og.og_url" type="text" disabled
                                    label="URL OG meta-tag"></v-text-field>
                      <v-text-field v-model="form.og.og_type" type="text" disabled
                                    label="Type OG meta-tag"></v-text-field>
                      <v-text-field v-model="form.og.og_image_url" type="text" disabled
                                    label="Image URL OG meta-tag"></v-text-field>
                      <div>
                        <label disabled="disabled">OG meta description</label>
                        <froala-view v-model="form.og.og_description"></froala-view>
                      </div>
                    </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <template v-if="form.settings.is_in_menu">
            <v-row class="ma-0 pa-0 mt-8">
              <v-col cols="12" class="ma-0 pa-0">
                <v-card>
                  <v-card-title>
                    Page slug
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="form.slug" type="text" disabled
                                      label="Page slug"></v-text-field>
                        <v-text-field v-model="form.menu.menu_item" type="text" disabled
                                      label="Menu title"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'Edit',
  data () {
    return {
      search: '',
      loading: false,
      confirmation: false,
      form: {
        main_part: null,
        aside_part: null,
        slug: null,
        meta: {
          meta_title: null,
          meta_description: null
        },
        og: {
          og_title: null,
          og_description: null,
          og_url: null,
          og_image_url: null,
          og_type: null
        },
        settings: {
          is_slider_on: false,
          images: []
        }
      }
    }
  },
  beforeMount () {
    this.show()
  },
  methods: {
    show () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/api/pages/${this.$route.params.id}`)
        .then((response) => {
          this.form = response.data
        })
    }
  }
}
</script>

<style scoped>

</style>
